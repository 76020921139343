<template lang="pug">
  tr.account
    td.sync
      AppToggle(
        :value="account.active"
        disabled
      )
    td.ota
      | {{ otaName }}
    td
      .actions
        AppProgressbar(
          v-if="isInProgress"
          :label="progressBarLabel"
          :value="progressData.value"
        )
        template(v-else)
          .hints
            .hint {{ $t("ota_accounts.last_update_at", { date: lastFinishedAt }) }}
            .hint.login-failed-warning(v-if="isLoginFailed") {{ $t("ota_accounts.login_failed") }}
          .get-data-button
            AppLoadingButton(
              :loading="account.loading"
              :disabled="!account.active || account.loading"
              :title="actionTitle"
              @click="$emit('action-click', account)"
            )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  export default {
    components: {
      AppLoadingButton: () => import("@/components/elements/AppButton/WithIcon/Loading"),
      AppProgressbar: () => import("@/components/elements/AppProgressbar"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppInputPassword: () => import("@/components/elements/AppInputPassword"),
      AppSpinner: () => import("@/components/elements/AppSpinner"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      account: {
        type: Object,
        required: true
      },
      otaName: {
        type: String,
        required: true
      },
      actionTitle: {
        type: String,
        required: true
      },
      progressBarLabel: {
        type: String,
        required: true
      },
      progressData: {
        type: Object,
        default: () => ({ value: 0 })
      }
    },

    mixins: [withPermissions],

    computed: {
      isLoginFailed() {
        return this.progressData.loginFailed
      },

      lastFinishedAt() {
        return this.progressData.lastFinishedAt || "—"
      },

      isInProgress() {
        return this.progressData.inProgress
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/helpers.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/mixins/common.sass"

  .account
    td
      +custom-table-td

      &.sync
        width: 80px

      &.editable
        min-width: 80px !important

        .editable-field
          width: 100%
          height: 30px
          line-height: 2.5

          +ie-only
            line-height: 0

        ::v-deep
          .password-input
            border: 1px solid rgba(89, 101, 231, 0.50)
            height: 30px

            &-icon
              top: 10px

          .username-input
            border: 1px solid rgba(89, 101, 231, 0.50)
            height: 30px

        .password-edited
          ::v-deep
            input
              color: $default-purple !important
              background: $default-purple-light

    .hints
      display: flex
      flex-direction: column
      align-items: flex-end

    .hint
      font-size: 0.8rem
      display: flex
      align-items: center
      color: $default-black

      &.login-failed-warning
        color: $default-red-dark

    input.editable-field
      &.changed
        color: $default-purple !important
        background: $default-purple-light

    .actions
      align-items: center
      justify-content: flex-end
      display: flex

      & > *
        margin-left: 20px
        text-align: right

      .action-icon
        .trash-icon
          +icon-button($default-purple)

  /* IE11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .account
      td
        white-space: nowrap

        &.editable
          min-width: 200px !important
</style>
